import { MdOutlinePrint } from 'react-icons/md';

const DesktopRecap = (props: {
    delivery: any;
    displayPrint?: boolean;
    onClick?: any;
}) => {
    const { delivery, displayPrint = false, onClick } = props;
    return (
        <div className="hidden sm:block mb-5 mx-[10px] p-[15px] border rounded-[30px]">
            <div className="flex justify-between">
                <p className="col-span-6 flex-grow text-lg font-bold text-navy-700">
                    Récapitulatif de la tournée:
                </p>
                {displayPrint && (
                    <div className="flex hover:cursor-pointer">
                        <MdOutlinePrint size={25} onClick={onClick} />
                    </div>
                )}
            </div>
            <table className="mt-[16px] w-full">
                <thead>
                    <tr>
                        <th className="mt-[20px] w-[60%] px-[10px] text-start text-sm">
                            <p className="text-sm font-bold text-gray-600 dark:text-white">
                                Produit
                            </p>
                        </th>
                        <th className="mt-[20px] w-[40%] px-[10px] text-start text-sm">
                            <p className="text-sm font-bold text-gray-600 dark:text-white">
                                Quantité
                            </p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {delivery &&
                        delivery.items.map((product: any) => {
                            return (
                                <tr className="border-t border-gray-200 h-[30px]">
                                    <td className="px-[10px]">
                                        <p className="font-medium text-sm">
                                            {product.name}
                                        </p>
                                    </td>
                                    <td className="px-[10px]">
                                        <p className="font-medium text-sm">
                                            {product.quantity} {product.unity}
                                        </p>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            <p className="mt-[6px] mb-[5px] text-sm text-[#A3AED0] dark:text-white">
                Poids brut total :{' '}
                {delivery &&
                    delivery?.items?.reduce(
                        (acc: any, item: any) =>
                            acc + item.brutWeight * item.quantity,
                        0,
                    )}{' '}
                kg
            </p>
            <p className="mt-[6px] mb-[5px] text-sm text-[#A3AED0] dark:text-white">
                Total: {delivery && delivery.items.length} produit
                {delivery && delivery.items !== 0 && 's'} - Poids brut:{' '}
                {delivery &&
                    delivery?.items?.reduce(
                        (acc: any, item: any) =>
                            acc + item.brutWeight * item.quantity,
                        0,
                    )}{' '}
                kg
            </p>
        </div>
    );
};

export default DesktopRecap;
