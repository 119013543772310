import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import Card from 'components/card';
import DateRangePicker from 'components/dataPicker';
import SearchSelector from 'components/fields/SearchSelector';
import { set } from 'date-fns';
import { downloadPaiementExport } from 'interfaces/invoice';
import { useState } from 'react';

const PaiementExportModal = (props: {
    isOpen: any;
    onClose: any;
    handleExport?: any;
    archived?: boolean;
}) => {
    const { isOpen, onClose } = props;

    const [filter, setFilter] = useState({
        startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        endDate: new Date(),
        paymentMethod: 'all',
    });

    const handleDateChange = (dates: any) => {
        const startDate = dates.startDate;
        const endDate = dates.endDate;
        setFilter((prevFilter: any) => ({
            ...prevFilter,
            startDate,
            endDate,
        }));
    };

    const handleExport = async () => {
        // get the same date and set to it 00:00:00 iso
        const start = set(filter.startDate, {
            hours: 0,
            minutes: 0,
            seconds: 0,
        }).toISOString();
        const end = set(filter.endDate, {
            hours: 23,
            minutes: 59,
            seconds: 59,
        }).toISOString();
        try {
            const blob = await downloadPaiementExport(
                start,
                end,
                filter.paymentMethod,
            );
            if (!blob) return;
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `export-simple-${new Date().toLocaleDateString()}.csv`;
            link.click();
            onClose();
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        <h1 className="text-xl font-bold">
                            Export des paiements
                        </h1>
                        <p className="mt-3 text-sm">
                            💡 Cet export vous permet de récupérer vos paiements
                            au format CSV.
                        </p>
                        <div className="flex gap-4 mt-5">
                            <DateRangePicker
                                label="Dates de paiement*"
                                defaultStartDate={filter.startDate}
                                defaultEndDate={filter.endDate}
                                onChange={handleDateChange}
                            />
                            <div className="w-[200px]">
                                <SearchSelector
                                    label="Moyen de paiement*"
                                    displayKey="name"
                                    inputSmall={true}
                                    value={filter.paymentMethod}
                                    options={[
                                        {
                                            _id: 'all',
                                            name: 'Tous',
                                        },
                                        {
                                            _id: 'cash',
                                            name: 'Espèces',
                                        },
                                        {
                                            _id: 'check',
                                            name: 'Chèque',
                                        },
                                        {
                                            _id: 'transfer',
                                            name: 'Virement',
                                        },
                                        {
                                            _id: 'credit-card',
                                            name: 'Carte bancaire',
                                        },
                                        {
                                            _id: 'direct-debit',
                                            name: 'Prélèvement',
                                        },
                                    ]}
                                    onSelect={(e: any) => {
                                        setFilter((prevFilter: any) => ({
                                            ...prevFilter,
                                            paymentMethod: e._id,
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mt-8 flex gap-2">
                            <button
                                onClick={onClose}
                                className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                            >
                                Annuler
                            </button>
                            <button
                                className="linear hover:bg-red600 col-span-12 ml-3 flex items-center justify-center rounded-xl bg-toola-500 px-3 py-3 text-xs font-medium text-white active:bg-toola-700 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                onClick={handleExport}
                            >
                                Exporter les factures
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default PaiementExportModal;
