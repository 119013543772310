import React from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';

import Dropdown from 'components/dropdown';

function ExtraMenu(props: {
    transparent?: boolean;
    vertical?: boolean;
    onOpen?: any;
    onCreateCreditNote?: any;
    onClose?: any;
    onPaiementExportModalOpen?: any;
    onSimpleExportModalOpen?: any;
}) {
    const {
        transparent,
        vertical,
        onCreateCreditNote,
        onPaiementExportModalOpen,
        onSimpleExportModalOpen,
    } = props;
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    return (
        <Dropdown
            button={
                <button
                    onClick={() => setOpen(!open)}
                    className={`flex items-center text-xl hover:cursor-pointer ${
                        transparent
                            ? 'bg-none text-white hover:bg-none active:bg-none'
                            : vertical
                              ? 'bg-none text-navy-700 dark:text-white'
                              : 'bg-lightPrimary p-2 text-toola-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10'
                    } linear justify-center rounded-lg font-bold transition duration-200`}
                >
                    {vertical ? (
                        <p className="text-[24px] hover:cursor-pointer">
                            <BsThreeDotsVertical />
                        </p>
                    ) : (
                        <BsThreeDots className="h-6 w-6" />
                    )}
                </button>
            }
            animation={
                'origin-top-right transition-all duration-300 ease-in-out'
            }
            classNames={`${transparent ? 'top-8' : 'top-11'} right-0 w-max`}
            children={
                <div className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                    <p
                        className="hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium"
                        onClick={() => {
                            onCreateCreditNote();
                        }}
                    >
                        Créer un avoir
                    </p>
                    <p
                        className="mt-3 hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium"
                        onClick={() => {
                            onPaiementExportModalOpen();
                            setOpen(false);
                        }}
                    >
                        Export des paiements
                    </p>
                    <p
                        className="mt-3 hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium"
                        onClick={() => {
                            onSimpleExportModalOpen();
                            setOpen(false);
                        }}
                    >
                        Export des factures
                    </p>
                    <p
                        className="mt-3 hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium"
                        onClick={() => navigate('/accounting-export')}
                    >
                        Export comptable
                    </p>
                </div>
            }
        />
    );
}

export default ExtraMenu;
