import { LuFileSpreadsheet } from 'react-icons/lu';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/hooks';
import { useNavigate, useParams } from 'react-router-dom';

import {
    deleteOrder,
    getOrder,
    getOrderDeliveryNote,
    markOrderReady,
    resolveOrderIssue,
    updateOrder,
} from 'interfaces/order';
import Card from 'components/card';
import { getCustomers } from 'interfaces/customer';
import { getProducts } from 'interfaces/products';
import InputField from 'components/fields/InputField';
import SearchSelector from 'components/fields/SearchSelector';
import OrderTable from './components/OrderTable';
import { getTotalHT } from '../createOrders/utils';
import GoBack from 'components/actions/GoBack';
import DeleteOrder from './components/Delete';
import { calculateTotalPurchasePrice } from 'utils/margin';
import GenerateInvoice from './components/GenerateInvoice';
import { generateInvoiceForSpecificOrder } from 'interfaces/invoice';
import { FaArrowUpLong } from 'react-icons/fa6';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { productsWithDisplayKey } from '../createOrders/utils/display';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const EditOrder = () => {
    const navigate = useNavigate();
    const params = useParams();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpenGenerateInvoice,
        onOpen: onOpenGenerateInvoice,
        onClose: onCloseGenerateInvoice,
    } = useDisclosure();
    const [warning, setWarning] = useState<any>(null);
    const [isReady, setIsReady] = useState<boolean>(false);
    const [quantityChanged, setQuantityChanged] = useState<boolean>(false);
    const [customers, setCustomers] = useState<any>([]);
    const [products, setProducts] = useState<any>([]);
    const [isFullyLoaded, setIsFullyLoaded] = useState<boolean>(false);
    const [order, setOrder] = useState<any>(null);
    const [defaultCustomer, setDefaultCustomer] = useState<any>(null);
    const [newItem, setNewItem] = useState<any>({
        discountType: 'none',
        discountValue: 0,
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [pdfBlob, setPdfBlob] = useState(null);
    const [orgOrder, setOrgOrder] = useState<any>(null);

    const handleChange = (e: any) => {
        setOrder((prevCustomer: any) => ({
            ...prevCustomer,
            [e.target.id]: e.target.value,
        }));
    };

    const handleItemChange = (e: any) => {
        setNewItem((prevCustomer: any) => ({
            ...prevCustomer,
            [e.target.id]: e.target.value,
        }));
    };

    const updateQuantity = (product: any, value: number) => {
        setQuantityChanged(true);
        order.items.find((item: any) => item === product).quantity = value;
        order.totalPrice = getTotalHT(order.items);
        setOrder({
            ...order,
        });
    };

    const updateLotNumber = (product: any, value: string) => {
        order.items.find((item: any) => item === product).lotNumber = value;
        setOrder({
            ...order,
        });
    };

    const updateComment = (product: any, value: number) => {
        order.items.find((item: any) => item === product).comment = value;
        setOrder({
            ...order,
        });
    };

    const updateUnityPrice = (product: any, value: number) => {
        order.items.find((item: any) => item === product).price = value;
        order.totalPrice = getTotalHT(order.items);
        setOrder({
            ...order,
        });
    };

    const updateDiscount = (product: any, value: number) => {
        order.items.find((item: any) => item === product).discountValue = value;
        order.totalPrice = getTotalHT(order.items);
        setOrder({
            ...order,
        });
    };

    const updateDiscountType = (product: any, value: string) => {
        order.items.find((item: any) => item === product).discountType = value;
        if (value === 'none') {
            order.items.find((item: any) => item === product).discountValue = 0;
        }
        order.totalPrice = getTotalHT(order.items);
        setOrder({
            ...order,
        });
    };

    const deleteItem = (item: any) => {
        setOrder((prevCustomer: any) => ({
            ...prevCustomer,
            items: prevCustomer.items.filter(
                (i: any) => i.productId !== item.productId,
            ),
            totalPrice: getTotalHT(
                prevCustomer.items.filter(
                    (i: any) => i.productId !== item.productId,
                ),
            ),
        }));
    };

    const initOrder = async (id: string) => {
        const res = await getOrder(id);
        if (res) {
            setOrder(res);
            setOrgOrder(JSON.stringify(res));
            initProducts(res.customerId);
            setIsReady(res.isReady);
            return;
        }
        navigate('/orders');
    };

    const cleanOrder = (order: any) => {
        const newItems = order.items.map((item: any) => {
            const newItem = { ...item };
            if (newItem.price === '') newItem.price = 0;
            delete newItem.totalPurchasePrice;
            return newItem;
        });
        return { ...order, items: newItems };
    };

    const editOrder = async () => {
        const data = { ...order };
        delete data.ownerId;
        delete data.createdAt;
        delete data.updatedAt;
        delete data.__v;
        delete data.viewed;
        const res = await updateOrder(cleanOrder(data));
        if (res.data) {
            toast.success('Modification enregistrée');
            navigate('/orders');
        }
    };

    const handleGeneratePDF = async () => {
        try {
            const blob = await getOrderDeliveryNote(params.id);
            //setPdfBlob(URL.createObjectURL(blob)); Use for testing
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `bon-livraison-${order.orderId || ''}.pdf`;
            link.click();
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    const getSelectedCustomer = () => {
        if (order && customers) {
            const customer = customers.find(
                (c: any) => c._id === order.customerId,
            );
            if (customer) {
                return customer;
            }
        }
        return null;
    };

    const initCustomers = async () => {
        const res = await getCustomers();
        if (res) setCustomers(res.data);
    };

    const initProducts = async (customerId: string) => {
        const res = await getProducts(customerId);
        if (res) {
            const productsForSales = res.data;
            setProducts(productsForSales);
            setIsFullyLoaded(true);
        }
    };

    // Add totatPurchasePrice to each item
    useEffect(() => {
        if (!products || !order) return;
        const updatedOrder = { ...order };
        for (let i = 0; i < updatedOrder.items.length; i++) {
            const item = updatedOrder.items[i];
            const product = products.find(
                (product: any) => product._id === item.productId,
            );
            console.log('product', product);
            if (product) {
                updatedOrder.items[i].totalPurchasePrice =
                    calculateTotalPurchasePrice(product);
            }
        }
        setOrder(updatedOrder);
    }, [products, isFullyLoaded]);

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            navigate('/login');
        }
        initOrder(params.id);
        initCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateProductsPrices = async (products: any) => {
        const newItemsUpdated = products.find(
            (product: any) => product._id === newItem._id,
        );
        let isNewOrderUpdated = false;
        const newOrdersItemsUpdated = order.items.map((item: any) => {
            const itemUpdated = products.find(
                (product: any) => product._id === item._id,
            );
            if (itemUpdated) {
                item.price = itemUpdated.price;
                isNewOrderUpdated = true;
            }
            return item;
        });

        const customer = customers.find(
            (customer: any) => customer._id === order.customerId,
        );
        const warning = `Prix unitaire HT modifié en fonction du prix négocié avec ${
            customer ? customer.company : 'le client'
        }.`;
        if (newItemsUpdated !== undefined) {
            setWarning(warning);
            setNewItem((prevCustomer: any) => ({
                ...prevCustomer,
                price: newItemsUpdated.price,
            }));
        }

        if (isNewOrderUpdated) {
            setWarning(warning);
            setOrder((prevCustomer: any) => ({
                ...prevCustomer,
                items: newOrdersItemsUpdated,
                totalPrice: getTotalHT(newOrdersItemsUpdated),
            }));
        }
    };

    const fetchProductsPrices = async (customerId: string) => {
        setWarning('Chargement des prix personnalisés en cours...');
        const res = await getProducts(customerId);
        if (res) {
            // const productsForSales = res.data.filter(
            //     (product: any) => product.category !== 'hidden',
            // );
            setProducts(res.data);
            updateProductsPrices(res.data);
        }
    };

    const getStatusList = (order: any) => {
        let status = [
            {
                status: 'En cours',
                _id: 'pending',
            },
            {
                status: 'Livré',
                _id: 'delivered',
            },
            {
                status: 'Annulé',
                _id: 'canceled',
            },
        ];
        if (order && order.invoiced === true) {
            status = status.filter((s: any) => s._id !== 'canceled');
        }
        return status;
    };

    useEffect(() => {
        if (!defaultCustomer) {
            setDefaultCustomer(getSelectedCustomer());
            return;
        }
        if (order && order.customerId) fetchProductsPrices(order.customerId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order?.customerId]);

    const alreadyExist = (product: any) => {
        return order.items.find(
            (product: any) => product.productId === newItem.productId,
        );
    };

    const resolveIssue = async () => {
        const res = await resolveOrderIssue(order._id);
        if (res) {
            setOrder((prevCustomer: any) => ({
                ...prevCustomer,
                issueToResolve: false,
            }));
        }
    };

    const isValidOrder = (order: any) => {
        return (
            orgOrder &&
            order.orderId &&
            order.status &&
            order.items.length > 0 &&
            order.deliveryDate &&
            order.customerId &&
            orgOrder !== JSON.stringify(order) &&
            order.totalPrice >= 0
        );
    };

    const totalBrutWeight = () => {
        return order.items
            .reduce(
                (acc: number, item: any) =>
                    acc +
                    (isNaN(Number(item.brutWeight * item.quantity))
                        ? 0
                        : Number(item.brutWeight * item.quantity)),
                0,
            )
            ?.toFixed(2);
    };

    const totalNetWeight = () => {
        return order.items
            .reduce(
                (acc: number, item: any) =>
                    acc +
                    (isNaN(Number(item.netWeight * item.quantity))
                        ? 0
                        : Number(item.netWeight * item.quantity)),
                0,
            )
            ?.toFixed(2);
    };

    const generateInvoice = async () => {
        const res = await generateInvoiceForSpecificOrder(order._id);

        if (res.data) {
            toast.success('Facture générée avec succès');
            window.open(res.data.url, '_blank');
            setOrder((prevOrder: any) => ({
                ...prevOrder,
                invoiced: true,
            }));
        }
        onCloseGenerateInvoice();
    };

    return (
        <div>
            <GoBack />
            <div className="mt-1 grid  grid-cols-12 gap-3">
                <DeleteOrder
                    isOpen={isOpen}
                    onClose={onClose}
                    description="Êtes-vous sûr de vouloir supprimer cette commande ?"
                    context="d'une commande"
                    archived={false}
                    deleteFunction={() => {
                        deleteOrder(params.id);
                        navigate('/orders');
                    }}
                />
                <GenerateInvoice
                    isOpen={isOpenGenerateInvoice}
                    onClose={onCloseGenerateInvoice}
                    onClick={generateInvoice}
                />
                <Card extra={'col-span-12 sm:col-span-8 w-full !p-5 border'}>
                    {/* Header */}
                    <div className="h-full w-full">
                        <div className="relative">
                            <div className="flex gap-[10px] absolute right-0 top-0">
                                <div className="flex gap-2 col-span-3 col-start-7">
                                    <button
                                        className="hidden sm:flex linear cursor-pointer items-center justify-center rounded-xl border border-[#01499b] px-2 py-1 text-xs text-[#01499b] transition duration-200 hover:bg-brand-600/5 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                                        onClick={handleGeneratePDF}
                                    >
                                        Générer un bon de livraison
                                    </button>
                                    {order && !order.invoiced && (
                                        <button
                                            className="hidden sm:flex linear cursor-pointer items-center justify-center rounded-xl border border-[#01499b] px-2 py-1 text-xs text-[#01499b] transition duration-200 hover:bg-brand-600/5 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                                            onClick={onOpenGenerateInvoice}
                                        >
                                            Facturer la commande
                                        </button>
                                    )}
                                    <button
                                        className="flex sm:hidden w-[50px] h-[50px] linear cursor-pointer items-center justify-center rounded-full px-2 py-2 text-xs text-[#01499b] transition duration-200 hover:bg-brand-600/5 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                                        onClick={handleGeneratePDF}
                                    >
                                        <LuFileSpreadsheet size={30} />
                                    </button>
                                </div>
                            </div>
                            <div className="h-full w-full">
                                <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                                    Commande n°{order ? order.orderId : ''}
                                </h4>
                                <p className="mt-1 text-base text-gray-600">
                                    {order && order.invoiced
                                        ? 'Commande déjà facturée, disponible en lecture-seule.'
                                        : ''}
                                </p>
                                {pdfBlob && (
                                    <div className="">
                                        <Document
                                            className="flex w-full border rounded-3xl bg-gray-400"
                                            file={pdfBlob}
                                        >
                                            <Page pageNumber={1} />
                                        </Document>
                                    </div>
                                )}
                                <div className="mt-5 grid grid-cols-1 gap-3 sm:grid-cols-12">
                                    <div className="col-span-3">
                                        <InputField
                                            label="Date de livraison*"
                                            placeholder="01/01/2021"
                                            id="deliveryDate"
                                            type="date"
                                            value={
                                                order && order.deliveryDate
                                                    ? order.deliveryDate.split(
                                                          'T',
                                                      )[0]
                                                    : ''
                                            }
                                            onChange={handleChange}
                                            disabled={
                                                order &&
                                                order.invoiced === false
                                                    ? false
                                                    : true
                                            }
                                            inputSmall={true}
                                        />
                                    </div>
                                    <div className="col-span-3">
                                        <SearchSelector
                                            label="Statut"
                                            options={getStatusList(order)}
                                            displayKey="status"
                                            value={order ? order.status : ''}
                                            onSelect={(e: any) => {
                                                setOrder(
                                                    (prevCustomer: any) => ({
                                                        ...prevCustomer,
                                                        status: e._id,
                                                    }),
                                                );
                                            }}
                                            inputSmall={true}
                                        />
                                        {order &&
                                            order.status === 'canceled' && (
                                                <p className="mt-1  ml-3 text-xs text-gray-400">
                                                    💡 Pour garantir une
                                                    traçabilitée parfaite pensez
                                                    à retirer le contenu de la
                                                    commande de votre stock
                                                    après annulation.
                                                </p>
                                            )}
                                    </div>
                                    <div className="mt-3 md:mt-0 col-span-3">
                                        <SearchSelector
                                            label="Client*"
                                            displayKey="company"
                                            options={customers}
                                            value={
                                                order ? order.customerId : ''
                                            }
                                            disabled={
                                                order &&
                                                order.invoiced === false
                                                    ? false
                                                    : true
                                            }
                                            onSelect={(e: any) => {
                                                setOrder(
                                                    (prevCustomer: any) => ({
                                                        ...prevCustomer,
                                                        customerId: e._id,
                                                    }),
                                                );
                                            }}
                                            inputSmall={true}
                                        />
                                    </div>
                                    <div className="mt-3 md:mt-0 col-span-3">
                                        <InputField
                                            label="Numéro purchase order (PO)"
                                            placeholder="PO-7578"
                                            id="purchaseOrderNumber"
                                            type="text"
                                            value={
                                                order
                                                    ? order.purchaseOrderNumber
                                                    : ''
                                            }
                                            onChange={handleChange}
                                            inputSmall={true}
                                        />
                                    </div>
                                </div>
                                <div className="mt-2 grid grid-cols-1 gap-3 xl:grid-cols-12">
                                    <div className="col-span-6">
                                        <InputField
                                            label="Note du client"
                                            placeholder={`Pouvez-vous livrer avant 8h ?`}
                                            id="customerNotes"
                                            type="text"
                                            value={
                                                order ? order.customerNotes : ''
                                            }
                                            disabled={
                                                order &&
                                                order.invoiced === false
                                                    ? false
                                                    : true
                                            }
                                            onChange={handleChange}
                                            inputSmall={true}
                                        />
                                    </div>
                                    <div className="col-span-6">
                                        <InputField
                                            label={`Note interne`}
                                            placeholder="Livraison prioritaire"
                                            id="internalNotes"
                                            type="text"
                                            value={
                                                order ? order.internalNotes : ''
                                            }
                                            disabled={
                                                order &&
                                                order.invoiced === false
                                                    ? false
                                                    : true
                                            }
                                            onChange={handleChange}
                                            inputSmall={true}
                                        />
                                    </div>
                                    <div className="col-span-6">
                                        <InputField
                                            label={`Note de livraison (figure sur le BL)`}
                                            placeholder="Livraison prioritaire"
                                            id="deliveryNotes"
                                            type="text"
                                            value={
                                                order ? order.deliveryNotes : ''
                                            }
                                            disabled={
                                                order &&
                                                order.invoiced === false
                                                    ? false
                                                    : true
                                            }
                                            onChange={handleChange}
                                            inputSmall={true}
                                        />
                                    </div>
                                    {order && !isReady && (
                                        <div className="flex my-auto col-span-6">
                                            <button
                                                className="mt-5 bg-toola-400 text-white text-[10px] px-2 py-3 rounded-xl hover:bg-toola-400/90"
                                                onClick={async (e: any) => {
                                                    e.stopPropagation();
                                                    await markOrderReady(
                                                        order?._id,
                                                    );
                                                    setIsReady(true);
                                                }}
                                            >
                                                Terminer la préparation
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {order && order.issueToResolve && (
                        <div className="mt-3 p-4 rounded-xl shadow-lg border">
                            <p className="text-red-500 text-sm">
                                Un problème ou un commentaire de livraison est
                                ouvert pour cette commande (voir note de
                                livraison).
                            </p>
                            <p
                                className="mt-2 text-sm p-2 text-center cursor-pointer rounded-xl bg-red-300 border"
                                onClick={resolveIssue}
                            >
                                Marquer comme résolu
                            </p>
                        </div>
                    )}
                </Card>
                <Card extra={'w-full !p-5 border col-span-12 sm:col-span-4'}>
                    {/* Header */}
                    <div className="h-full w-full">
                        <div className="relative">
                            <div className="flex gap-[10px] absolute right-0 top-0"></div>
                            <div className="h-full w-full">
                                <div className="flex justify-between">
                                    <h4 className="h-[50px] flex flex-col justify-end sm:h-auto text-lg font-medium text-navy-700 dark:text-white">
                                        Client
                                    </h4>
                                    <div className="col-span-2 col-start-10">
                                        <button
                                            className="linear flex cursor-pointer items-center justify-center rounded-xl border border-red-500 px-2 py-2 text-xs text-red-500 transition duration-200 hover:bg-red-50 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                                            onClick={() => onOpen()}
                                        >
                                            Supprimer la commande
                                        </button>
                                    </div>
                                </div>
                                <p className="mt-2 text-base text-gray-600">
                                    {getSelectedCustomer() &&
                                        `Nom: ${
                                            getSelectedCustomer().company
                                        }`}{' '}
                                </p>
                                <p className="mt-2 text-base text-gray-600">
                                    {getSelectedCustomer() &&
                                        `Email: ${
                                            getSelectedCustomer().mail
                                        }`}{' '}
                                </p>
                                <p className="mt-2 text-base text-gray-600">
                                    {getSelectedCustomer() &&
                                        `Téléphone: ${
                                            getSelectedCustomer().phone ||
                                            'Non renseigné'
                                        }`}
                                    {!getSelectedCustomer() &&
                                        'Aucune information client'}
                                </p>
                                <p
                                    className="flex gap-2 mt-2 text-base text-blue-500 hover:cursor-pointer"
                                    onClick={() =>
                                        window.open(
                                            `/customer/${order.customerId}`,
                                            '_blank',
                                        )
                                    }
                                >
                                    <FaExternalLinkAlt
                                        size={12}
                                        className="flex my-auto"
                                    />
                                    Voir la fiche client
                                </p>
                            </div>
                        </div>
                    </div>
                </Card>
                <Card extra={'col-span-12 w-full !p-5 border'}>
                    {/* Header */}
                    <div className="h-full w-full">
                        <div>
                            <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                                Contenu de la commande
                            </h4>
                            {order && !order.invoiced && (
                                <>
                                    {warning && (
                                        <p className="mt-1 text-sm text-red-500">
                                            {warning}
                                        </p>
                                    )}
                                    <div className="mt-2 grid grid-cols-1 gap-3 xl:grid-cols-12">
                                        <div className="z-50 col-span-4">
                                            <SearchSelector
                                                displayKey="displayKey"
                                                secondaryKey="ref"
                                                label="Produit*"
                                                options={productsWithDisplayKey(
                                                    products,
                                                )}
                                                onSelect={(e: any) => {
                                                    setNewItem(
                                                        (
                                                            prevCustomer: any,
                                                        ) => ({
                                                            ...prevCustomer,
                                                            name: e.name,
                                                            productId: e._id,
                                                            _id: e._id,
                                                            price: e.price,
                                                        }),
                                                    );
                                                }}
                                                inputSmall={true}
                                            />
                                        </div>
                                        <div className="mt-7 md:mt-0 col-span-1">
                                            <InputField
                                                label="Quantité*"
                                                placeholder="1"
                                                id="quantity"
                                                type="number"
                                                onChange={handleItemChange}
                                                disabled={
                                                    order &&
                                                    order.invoiced === false
                                                        ? false
                                                        : true
                                                }
                                                inputSmall={true}
                                            />
                                        </div>
                                        <div className="mt-7 md:mt-0 col-span-2">
                                            <InputField
                                                label="Prix unitaire HT*"
                                                placeholder="17"
                                                id="price"
                                                type="number"
                                                value={newItem.price}
                                                onChange={handleItemChange}
                                                disabled={
                                                    order &&
                                                    order.invoiced === false
                                                        ? false
                                                        : true
                                                }
                                                inputSmall={true}
                                            />
                                        </div>
                                        <div className="col-span-3">
                                            <button
                                                className={`sm:mt-6 linear col-span-3 flex items-center justify-center rounded-xl ${
                                                    alreadyExist(
                                                        newItem.productId,
                                                    )
                                                        ? 'bg-[#32383D] hover:bg-[#32383D] active:bg-[#32383D] cursor-default'
                                                        : 'bg-toola-500 hover:bg-toola-600 active:bg-toola-700'
                                                } px-10 py-2 text-sm font-medium text-white`}
                                                onClick={() => {
                                                    if (
                                                        !alreadyExist(
                                                            newItem.productId,
                                                        )
                                                    ) {
                                                        if (
                                                            !newItem.productId ||
                                                            !newItem.quantity ||
                                                            !newItem.price
                                                        )
                                                            return toast.error(
                                                                'Veuillez remplir tous les champs',
                                                            );
                                                        setOrder(
                                                            (
                                                                prevCustomer: any,
                                                            ) => ({
                                                                ...prevCustomer,
                                                                items: [
                                                                    ...prevCustomer.items,
                                                                    newItem,
                                                                ],
                                                                totalPrice:
                                                                    getTotalHT([
                                                                        ...prevCustomer.items,
                                                                        newItem,
                                                                    ]),
                                                            }),
                                                        );
                                                        setNewItem({
                                                            discountType:
                                                                'none',
                                                            discountValue: 0,
                                                            ...newItem,
                                                        });
                                                    }
                                                }}
                                            >
                                                Ajouter
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                            <p className="mt-4 text-base text-gray-600">
                                Récapitulatif de la commande
                            </p>
                            {order && (
                                <OrderTable
                                    order={order}
                                    products={products}
                                    handleQtyChange={updateQuantity}
                                    handleLotNumberChange={updateLotNumber}
                                    handlePriceChange={updateUnityPrice}
                                    handleDiscountChange={updateDiscount}
                                    handleDiscountTypeChange={
                                        updateDiscountType
                                    }
                                    handleCommentChange={updateComment}
                                    onDelete={deleteItem}
                                />
                            )}
                        </div>
                        {quantityChanged && (
                            <p className="mt-4 text-sm text-red-500">
                                <p className="mt-1  ml-3 text-sm text-gray-400">
                                    💡 Pour garantir une traçabilitée parfaite
                                    pensez à réajuster votre stock après édition
                                    de la commande.
                                </p>
                            </p>
                        )}
                        {order && order.items && order.items.length > 4 && (
                            <div className="mt-2 flex gap-2">
                                <p
                                    className="flex my-auto text-toola-500 hover:cursor-pointer hover:text-toola-600"
                                    onClick={() => {
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'auto',
                                        }); // behaviors: smooth, auto, instant
                                    }}
                                >
                                    Ajouter un produit
                                </p>
                                <FaArrowUpLong
                                    size={10}
                                    className="flex my-auto text-toola-500"
                                />
                            </div>
                        )}
                        {order && order.items && order.items.length > 0 && (
                            <div className="flex gap-10">
                                <div className="mt-5 flex gap-[15px]">
                                    <p className="col-span-4 sm:col-span-1 text-lg text-navy-700 dark:text-white">
                                        Total HT
                                    </p>
                                    <p className="text-lg font-bold text-navy-700 dark:text-white">
                                        {order.totalPrice.toFixed(2)}€
                                    </p>
                                </div>
                                {totalNetWeight() > 0 && (
                                    <div className="mt-5 flex gap-[15px]">
                                        <p className="col-span-4 sm:col-span-1 text-lg text-navy-700 dark:text-white">
                                            Poids net total:
                                        </p>
                                        <p className="text-lg font-bold text-navy-700 dark:text-white">
                                            {totalNetWeight()}
                                            Kg
                                        </p>
                                    </div>
                                )}
                                {totalBrutWeight() > 0 && (
                                    <div className="mt-5 flex gap-[15px]">
                                        <p className="col-span-4 sm:col-span-1 text-lg text-navy-700 dark:text-white">
                                            Poids brut total:
                                        </p>
                                        <p className="text-lg font-bold text-navy-700 dark:text-white">
                                            {totalBrutWeight()}
                                            Kg
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Card>
                {order && (
                    <div className="flex ml-5">
                        <button
                            className={`linear col-span-12 sm:col-span-3 flex items-center justify-center rounded-xl px-10 py-2 text-sm font-medium text-white ${
                                isValidOrder(order)
                                    ? 'bg-[#0260CB] hover:bg-[#01499b] active:bg-[#024086]'
                                    : 'bg-[#32383D] hover:bg-[#32383D] active:bg-[#32383D] cursor-default'
                            }`}
                            onClick={() => {
                                isValidOrder(order) && editOrder();
                            }}
                        >
                            Sauvegarder
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EditOrder;
